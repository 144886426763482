<template>
  <v-footer
    tile
    height="54"
    class="font-weight-medium shadow-sm ma-4 rounded-lg"
    :color="$vuetify.theme.dark ? 'dark' : 'white'"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <div class="d-flex align-center gap-2">
      <template v-if="!hidePoweredBy">
        <img
          v-if="$vuetify.theme.dark"
          height="12"
          alt="Voy Logo"
          src="/img/logo/voy-dark.png"
          class="unselectable non-draggable"
        />
        <img
          v-else
          height="12"
          alt="Voy Logo"
          class="unselectable non-draggable"
          src="/img/logo/voy-light.png"
        />
      </template>

      <v-chip v-if="config.opMode" color="warning" label small>
        Modo técnico
      </v-chip>
    </div>
    <v-spacer />
    <v-alert
      @click="supportDialog = true"
      style="cursor: pointer"
      dense
      class="mb-0 py-1 text-12"
      color="primary"
      text
    >
      Suporte
    </v-alert>
    <support-dialog v-model="supportDialog" />
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SupportDialog from "@/components/global/SupportDialog.vue";
import { email, hidePoweredBy } from "@/themeConfig.js";

export default {
  components: {
    SupportDialog,
  },
  data: () => ({
    email,
    hidePoweredBy,
    supportDialog: false,
  }),
  methods: {
    sendEmail(email) {
      window.open(`mailto:${email}`, "_blank");
    },
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapGetters("auth", ["config"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
  },
};
</script>
