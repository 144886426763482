<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card class="rounded-xl">
      <v-card-title class="text-h6 mx-0 px-4">
        Suporte
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div class="pa-4 pt-2">
        <div
          v-if="
            selectedOrganization?.CommercialResponsible &&
            hasPermission(supportContactPermission)
          "
          class="mb-4"
        >
          <span class="text-subtitle-2">Responsável Comercial</span>
          <v-card
            elevation="0"
            class="rounded pa-3 pr-0 d-flex align-center gap-3"
          >
            <base-avatar
              :src="selectedOrganization?.CommercialResponsible?.photo"
              :seed="selectedOrganization?.CommercialResponsible?.id"
              :size="45"
              color="secondary lighten-2"
            />
            <div class="d-flex flex-column">
              <span class="text-subtitle-2">
                {{ selectedOrganization?.CommercialResponsible?.name }}
              </span>
              <span class="text-caption">
                {{ selectedOrganization?.CommercialResponsible?.phone }}
              </span>
            </div>
            <v-spacer />
            <v-btn
              text
              small
              class="text--disabled"
              icon
              @click="
                openWhatsApp(
                  selectedOrganization?.CommercialResponsible?.phone,
                  'commercial'
                )
              "
            >
              <v-icon color="green">mdi-whatsapp</v-icon>
            </v-btn>
          </v-card>
        </div>

        <v-divider
          v-if="
            selectedOrganization?.CommercialResponsible &&
            hasPermission(supportContactPermission)
          "
          class="my-4"
        />

        <div v-if="supportContact && hasPermission(supportContactPermission)">
          <span class="text-subtitle-2">Atendimento</span>
          <v-card
            elevation="0"
            class="rounded pa-3 pr-0 d-flex align-center gap-3"
          >
            <v-avatar color="grey lighten-2" size="45">
              <v-icon>mdi-account-wrench</v-icon>
            </v-avatar>
            <div class="d-flex flex-column">
              <span class="text-subtitle-2">Suporte ao Produtor</span>
              <span class="text-caption">{{ supportContact }}</span>
            </div>
            <v-spacer />
            <v-btn
              icon
              text
              small
              class="text--disabled"
              @click="openWhatsApp(supportContact, 'support')"
            >
              <v-icon color="green">mdi-whatsapp</v-icon>
            </v-btn>
          </v-card>
        </div>

        <div v-if="supportFinance && hasPermission(supportContactPermission)">
          <v-card
            elevation="0"
            class="rounded pa-3 pr-0 d-flex align-center gap-3"
          >
            <v-avatar color="grey lighten-2" size="45">
              <v-icon>mdi-finance</v-icon>
            </v-avatar>
            <div class="d-flex flex-column">
              <span class="text-subtitle-2">Financeiro</span>
              <span class="text-caption">{{ supportFinance }}</span>
            </div>
            <v-spacer />
            <v-btn
              icon
              text
              small
              class="text--disabled"
              @click="openWhatsApp(supportFinance, 'finance')"
            >
              <v-icon color="green">mdi-whatsapp</v-icon>
            </v-btn>
          </v-card>
        </div>

        <div v-if="supportSAC">
          <v-card
            elevation="0"
            class="rounded pa-3 pr-0 d-flex align-center gap-3"
          >
            <v-avatar color="grey lighten-2" size="45">
              <v-icon>mdi-face-agent</v-icon>
            </v-avatar>
            <div class="d-flex flex-column">
              <span class="text-subtitle-2">SAC</span>
              <span class="text-caption">{{ supportSAC }}</span>
            </div>
            <v-spacer />
            <v-btn
              icon
              text
              small
              class="text--disabled"
              @click="copyWhatsAppLink(supportSAC, 'support')"
            >
              <v-icon :color="shareButtonState.color">{{
                shareButtonState.icon
              }}</v-icon>
            </v-btn>
          </v-card>
        </div>

        <div v-if="email">
          <v-card
            elevation="0"
            class="rounded pa-3 pr-0 d-flex align-center gap-3"
          >
            <v-avatar color="grey lighten-2" size="45">
              <v-icon>mdi-mailbox</v-icon>
            </v-avatar>
            <div class="d-flex flex-column">
              <span class="text-subtitle-2">Email</span>
              <span class="text-caption">{{ email }}</span>
            </div>
            <v-spacer />
            <v-btn
              icon
              text
              small
              class="text--disabled"
              @click="sendEmail(email)"
            >
              <v-icon color="info">mdi-email-fast</v-icon>
            </v-btn>
          </v-card>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import RECORDER_SERVICE from "@/services/support/recorder";
const {
  VUE_APP_SUPPORT_CONTACT,
  VUE_APP_SUPPORT_SAC,
  VUE_APP_SUPPORT_FINANCE,
} = process.env;
import { email } from "@/themeConfig.js";

export default {
  name: "SupportDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
  },
  data: () => ({
    email,
    instance: null,
    loadingWhatsapp: false,
    supportContact: VUE_APP_SUPPORT_CONTACT,
    supportSAC: null,
    supportFinance: VUE_APP_SUPPORT_FINANCE,
    supportContactPermission: [
      2, 4, 8, 16, 64, 128, 256, 512, 2048, 4096, 8192, 16384, 65536, 131072,
      262144, 524288, 1048576, 2097152, 4194304, 8388608, 268435456, 536870912,
      1073741824,
    ],
    error: null,
    shareButtonState: {
      color: "info",
      icon: "mdi-share-variant",
    },
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
  },
  methods: {
    close() {
      this.dialog = false;
    },
    handleWhatsAppClick() {
      if (!this.supportSAC) {
        this.fetchInstance();
      } else {
        window.open(this.supportSAC, "_blank");
      }
    },
    async fetchInstance() {
      this.loadingWhatsapp = true;
      try {
        const response = await RECORDER_SERVICE.getInstanceById();
        this.instance = response.data[0];
        this.supportSAC = `//wa.me/${this.instance.phone}`;
      } catch (error) {
        console.error("Erro ao buscar instância:", error);
      } finally {
        this.loadingWhatsapp = false;
      }
    },
    openWhatsApp(phone, type) {
      const cleanPhone = phone?.replace(/\D/g, "") || "";
      let message = "";

      if (type === "commercial") {
        message = `Olá ${this.selectedOrganization?.CommercialResponsible?.name}, sou da organização ${this.selectedOrganization?.name} e gostaria de falar sobre assuntos comerciais.`;
      } else {
        message = `Olá, sou da organização ${this.selectedOrganization?.name} e preciso de suporte.`;
      }

      const whatsappUrl = `https://wa.me/${cleanPhone}?text=${encodeURIComponent(
        message
      )}`;
      window.open(whatsappUrl, "_blank");
    },
    async copyWhatsAppLink(phone, type) {
      const cleanPhone = phone?.replace(/\D/g, "") || "";
      const message = `Olá, sou da organização ${this.selectedOrganization?.name} e preciso de suporte.`;
      const whatsappUrl = `https://wa.me/${cleanPhone}?text=${encodeURIComponent(
        message
      )}`;

      if (navigator.share) {
        try {
          await navigator.share({
            title: "Suporte via WhatsApp",
            text: message,
            url: whatsappUrl,
          });
          this.showSuccessState();
        } catch (error) {
          this.error = error;
          if (error.name !== "AbortError") {
            this.showErrorState();
          }
        }
      } else {
        try {
          await navigator.clipboard.writeText(whatsappUrl);
          this.showSuccessState();
        } catch (error) {
          this.error = error;
          this.showErrorState();
        }
      }
    },
    sendEmail(email) {
      window.open(`mailto:${email}`, "_blank");
    },
    showSuccessState() {
      this.shareButtonState = {
        color: "success",
        icon: "mdi-check",
      };
      setTimeout(() => {
        this.shareButtonState = {
          color: "info",
          icon: "mdi-share-variant",
        };
      }, 3000);
    },
    showErrorState() {
      this.shareButtonState = {
        color: "error",
        icon: "mdi-alert",
      };
      setTimeout(() => {
        this.shareButtonState = {
          color: "info",
          icon: "mdi-share-variant",
        };
      }, 3000);
    },
  },
  mounted() {
    this.fetchInstance();
  },
};
</script>
